<template>
  <div>
    <b-row>
      <b-colxx xxs="12" class="mt-4 mb-5">
        <h1>Management</h1>
        <!-- <b-tabs
          nav-class="separator-tabs ml-0 mb-5 text-uppercase"
          content-class="tab-content"
          :no-fade="true"
        >
          <b-tab title="users"><p>I'm the first tab</p></b-tab>
          <b-tab title="teams"><p>I'm the first tab</p></b-tab>
          <b-tab title="roles"><p>I'm the first tab</p></b-tab>
          <b-tab title="approval codes"><p>I'm the first tab</p></b-tab>
          <b-tab title="claim master records"><p>I'm the first tab</p></b-tab>
        </b-tabs> -->
        <!-- <b-nav nav-class="separator-tabs ml-0 mb-5 text-uppercase">
          <b-nav-item active to="/admin/management/users">Users</b-nav-item>
          <b-nav-item>Inactive</b-nav-item>
          <b-nav-item disabled>Disabled</b-nav-item>
        </b-nav> -->
      </b-colxx>
    </b-row>
  </div>
</template>

<script>
export default {
  created() {
    console.log(this.$route.path);
  }
};
</script>

<style lang="scss" scoped>
</style>